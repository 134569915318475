<template>
<election-module-card title="Menighedsrådsvalg" card-icon-class="fad fa-sitemap fa-3x"
                          description="Her ser du en liste over stiftets menighedsråd."
                          button-icon-class="fal fa-sitemap"
                          button-text="Gå til menighedsråd"
                          card-active="true"
                          :route="route">
        <small slot="footer">&nbsp;</small>
    </election-module-card>
</template>

<script>
    import ElectionModuleCard from "../Card/ElectionModuleCard.vue";

    export default {
        name: "ElectionBoardConstitutionCard",
        extends : ElectionModuleCard,
        components:{
            ElectionModuleCard
        }
    }
</script>

<style scoped>

</style>