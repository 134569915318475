


























































































































































// Libs
import $ from "jquery";
import lodash from 'lodash'
import moment from "moment";
import PortalMixin from "@/mixins/PortalMixin";
import { mapGetters } from "vuex";
import AccessRoleTypes from '@/services/AccessRoleTypes';
import OrganizationLevelTypes from "@/services/OrganizationLevelTypes";
import ElectionBoardCongregationsListCard from "@/components/Election/Stift/ElectionBoardCongregationsListCard.vue";
import ElectionModuleCard from "@/components/Election/Card/ElectionModuleCard.vue";
import PortalModuleCard from '@/components/Portal/Card/PortalModuleCard.vue';
import UserGroupHelper from "@/services/UserGroupHelper";
import PortalModuleExternalCard from "@/components/Portal/Card/PortalModuleExternalCard.vue";

export default {
    name: "PortalStiftHome",
    mixins: [PortalMixin],
    components: {
        PortalModuleExternalCard,
        ElectionBoardCongregationsListCard,
        ElectionModuleCard,
        PortalModuleCard
    },
    props: {},
    data() {
        return {
            environment: process.env.NODE_ENV.trim(),
            AccessRoleTypes,
            OrganizationLevelTypes,
            lodash: lodash,
            moment: moment,
            getDataLoading: false,
            showStructureChange: false,
            layout: {
                readMore: false
            }
        }
    },
    updated() {
        $('.selectpicker').selectpicker('refresh');
    },
  
    computed: {
        ...mapGetters(["hasElectionAdminGlobalRole","getElectionYear"])
    },
    methods: {
        functionBlocked() {
            return this.environment === "production" || this.environment === "test";
        },
      generateLink(mid) {
        return `https://persontjek.kirkenettet.dk/#/stift/${mid}/sbl`;
      }
       
    },
    created() {


    }

}

