































































































































































































































// Libs
import store from '../../store';
import $ from "jquery";
import lodash from 'lodash'
import moment from "moment";
import {mapActions, mapGetters} from 'vuex'

// Cards:
import ElectionModuleCard from "@/components/Election/Card/ElectionModuleCard.vue";
import ElectionBoardEditCard from '@/components/Election/Card/ElectionBoardEditCard.vue';
import ElectionBoardCommunicationCard from '@/components/Election/Card/ElectionBoardCommunicationCard.vue';
import ElectionBoardHowtoCard from '@/components/Election/Card/ElectionBoardHowtoCard.vue';
import ElectionBoardMaterialCard from '@/components/Election/Card/ElectionBoardMaterialCard.vue';
import ElectionBoardAssemblyCard from '@/components/Election/Card/ElectionBoardAssemblyCard.vue';
import ElectionBoardAssemblyExtraCard from '@/components/Election/Card/ElectionBoardAssemblyExtraCard.vue';
import ElectionBoardConstitutionCard from '@/components/Election/Card/ElectionBoardConstitutionCard.vue';
import ElectionBoardFillingCard from "@/components/Election/Card/ElectionBoardFillingCard.vue";
import ElectionBoardVoteElectionCard from "@/components/Election/Card/ElectionBoardVoteElectionCard.vue";
import ElectionBoardCongregationsListCard from "@/components/Election/Stift/ElectionBoardCongregationsListCard.vue"
import EmptyCard from "@/components/Portal/Card/PortalModuleEmptyCard.vue";

import GroupPicker from '@/components/GroupMembershipDropDown.vue';
import AccessRoleTypes from '@/services/AccessRoleTypes';
import ElectionNavigationMixin from "@/mixins/ElectionNavigationMixin";
import ElectionRightsBox from "@/components/Election/ElectionRightsBox.vue";
import ElectionType from "@/services/Model/Election/ElectionType";
import UserGroupHelper from "@/services/UserGroupHelper";

import { ElectionMaterialResolver } from "@/services/Model/Election/ElectionMaterialResolver";

export default {
    name: "ElectionHome",
    mixins: [ElectionNavigationMixin],
    components: {
        GroupPicker,
        ElectionModuleCard,
        ElectionBoardEditCard,
        ElectionBoardCommunicationCard,
        ElectionBoardHowtoCard,
        ElectionBoardMaterialCard,
        ElectionBoardAssemblyCard,
        ElectionBoardAssemblyExtraCard,
        ElectionBoardConstitutionCard,
        ElectionBoardFillingCard,
        ElectionBoardVoteElectionCard,
        ElectionBoardCongregationsListCard,
        ElectionRightsBox,
        EmptyCard
    },
    props: {
        mid: {
            required: false
        }
    },
    data() {
        return {
            lodash: lodash,
            moment: moment,
            ElectionType,
            accesstypes: AccessRoleTypes,
            environment: process.env.NODE_ENV.trim(),
            layout: {
                readMore: false,
                showBoardMembers: false,
                showElectionSettingsPoup: false, 
                constitutionReadMore: false,
                fillOptionsReadMore: false,
                pollOptionsReadMore: false
            }
        }
    },
    updated() {
        $('.selectpicker').selectpicker('refresh');
    },
    computed: {
        noActiveUserRoleSelected: () => {
            return store.getters.activeUserRole === {};
        },
        activeUserRoleText: () => {

            if (store.getters.activeUserRole != null && store.getters.activeUserRole.accessRoles != null) {
                console.log("item", store.getters.activeUserRole);
                return store.getters.activeUserRole.groupName;
            }

            return "Intet område valgt";
        },
    },
    methods: {
        environmentIsProd() {
            return this.environment === "production";
        },
        electionMaterialAvailable() {
            return ElectionMaterialResolver.ElectionMaterialAvailable(this.electionDocument);
        }
    },
    created(){
        
        if(this.activeUserRole && this.activeUserRole.groupValue !== "" && this.midFromUrl !== this.activeUserRole.groupValue)
            this.$router.push("/valg");

    }

}

